import React, { useState } from "react";
import "./services.css";

const Services = () =>{

    const [toggleState, setToggleState] = useState(0);
    
    const toggleTab = (index) => {
        setToggleState(index);
    }

    return(
        <section className="services section" id="services">
            <h2 className="section__title">Services</h2>
            <span className="section__subtitle">What I offer</span>

            <div className="services__container container grid">
                <div className="services__content">
                    <div>
                        <i className="uil uil-web-grid services__icon"></i>
                        <h3 className="services__title"> Responsive <br></br> Websites</h3>
                    </div>

                    <span className="services__button" onClick={()=> toggleTab(1)}>View more
                    <i className="uil uil-arrow-right services__button-icon"></i></span>

                    <div className={toggleState === 1 ? "services__modal active-modal" : "services__modal"}>
                        <div className="services__modal-content">
                            <i onClick={()=> toggleTab(0)} className="uil uil-times services__modal-close"></i>

                            <h3 className="services__modal-title"> Responsive Websites </h3>

                            <p className="services__modal-description">Service more than 2 years of exprience. Providing quality work to partners.</p>

                            <ul className="services__modal-services grid">
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Develop the User Interface
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Responsive Webpage development
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Provide Optimal Performance
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Convert UI/UX designs into products
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Create Landing Pages and Admin Panels
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                
                </div>

                <div className="services__content">
                    <div>
                        <i className="uil uil-arrow services__icon"></i>
                        <h3 className="services__title"> Admin <br/> Panels </h3>
                    </div>

                    <span onClick={()=> toggleTab(2)} className="services__button">View more
                    <i className="uil uil-arrow-right services__button-icon"></i></span>

                    <div className={toggleState === 2 ? "services__modal active-modal" : "services__modal"}>
                        <div className="services__modal-content">
                            <i onClick={()=> toggleTab(0)} className="uil uil-times services__modal-close"></i>

                            <h3 className="services__modal-title">Admin Panels</h3>

                            <p className="services__modal-description">Service more than 2 years of exprience. Providing quality work to partners.</p>

                            <ul className="services__modal-services grid">
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Develop the User Interface
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Responsive Webpage development
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Provide Optimal Performance
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Convert UI/UX designs into products
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Create Landing Pages and Admin Panels
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                
                </div>

                <div className="services__content">
                    <div>
                        <i className="uil uil-window services__icon"></i>
                        <h3 className="services__title"> Landing <br/> Pages </h3>
                    </div>

                    <span onClick={() =>toggleTab(3)} className="services__button">View more
                    <i className="uil uil-arrow-right services__button-icon"></i></span>

                    <div className={toggleState === 3 ? "services__modal active-modal" : "services__modal"}>
                        <div className="services__modal-content">
                            <i onClick={()=> toggleTab(0)} className="uil uil-times services__modal-close"></i>

                            <h3 className="services__modal-title">Landing Pages</h3>

                            <p className="services__modal-description">Service more than 2 years of exprience. Providing quality work to partners.</p>

                            <ul className="services__modal-services grid">
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Develop the User Interface
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Responsive Webpage development
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Provide Optimal Performance
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Convert UI/UX designs into products
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Create Landing Pages and Admin Panels
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                
                </div>


            </div>
        </section>
    )
}
export default Services;